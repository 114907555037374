import React, { useState, useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase';
import { useNavigate, Link } from 'react-router-dom';
import './dashboard.css';

const Dashboard = () => {
    const [events, setEvents] = useState([]);
    const [editingEvent, setEditingEvent] = useState(null);
    const [newEvent, setNewEvent] = useState({
        date: '',
        month: '',
        year: new Date().getFullYear().toString(),
        name: '',
        description: '',
        time: '',
        location: '',
        link: '',
        postEventLink: '',
        image: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchEvents();
    }, []);

    const fetchEvents = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'events'));
            let eventsList = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            // Sort events by date
            eventsList.sort((a, b) => {
                // Compare years
                const yearDiff = parseInt(a.year) - parseInt(b.year);
                if (yearDiff !== 0) return yearDiff;

                // Compare months
                const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
                const monthA = months.indexOf(a.month.toUpperCase());
                const monthB = months.indexOf(b.month.toUpperCase());
                if (monthA !== monthB) return monthA - monthB;

                // Compare days
                return parseInt(a.date) - parseInt(b.date);
            });

            setEvents(eventsList);
        } catch (error) {
            console.error('Error fetching events:', error);
            setError('Failed to fetch events');
        }
    };

    const handleLogout = async () => {
        try {
            await signOut(getAuth());
            navigate('/admin');
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    const formatUrl = (url) => {
        if (!url) return '';
        url = url.trim();
        if (!url.match(/^https?:\/\//)) {
            return `https://${url}`;
        }
        return url;
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(file);
            const previewUrl = URL.createObjectURL(file);
            setImagePreview(previewUrl);
        }
    };

    const handleImageUpload = async (file) => {
        if (!file) return null;

        try {
            const fileName = `${Date.now()}-${file.name}`;
            const storageRef = ref(storage, `events/${fileName}`);
            const snapshot = await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(snapshot.ref);
            return downloadURL;
        } catch (error) {
            console.error('Error uploading image:', error);
            throw error;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const eventData = { ...newEvent };

            if (selectedImage) {
                const imageUrl = await handleImageUpload(selectedImage);
                if (imageUrl) {
                    eventData.image = imageUrl;
                }
            }

            console.log('Event Data:', eventData);

            await addDoc(collection(db, 'events'), {
                ...eventData,
                month: eventData.month.toUpperCase(),
                link: formatUrl(eventData.link),
                postEventLink: formatUrl(eventData.postEventLink),
                createdAt: new Date()
            });

            // Reset form
            setNewEvent({
                date: '',
                month: '',
                year: new Date().getFullYear().toString(),
                name: '',
                description: '',
                time: '',
                location: '',
                link: '',
                postEventLink: '',
                image: ''
            });
            setSelectedImage(null);
            setImagePreview(null);
            
            await fetchEvents();
        } catch (error) {
            console.error('Error:', error);
            setError('Failed to create event');
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (eventId) => {
        if (window.confirm('Are you sure you want to delete this event?')) {
            try {
                await deleteDoc(doc(db, 'events', eventId));
                setSuccess('Event deleted successfully!');
                fetchEvents();
            } catch (error) {
                console.error('Error deleting event:', error);
                setError('Failed to delete event');
            }
        }
    };

    const handleEdit = (event) => {
        setEditingEvent(event);
        setNewEvent({
            date: event.date,
            month: event.month,
            year: event.year,
            name: event.name,
            description: event.description,
            time: event.time,
            location: event.location,
            link: event.link,
            postEventLink: event.postEventLink,
            image: event.image
        });
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess('');

        try {
            const eventRef = doc(db, 'events', editingEvent.id);
            const eventData = {
                ...newEvent,
                month: newEvent.month.toUpperCase(),
                link: formatUrl(newEvent.link),
                postEventLink: formatUrl(newEvent.postEventLink)
            };
            await updateDoc(eventRef, eventData);
            setSuccess('Event updated successfully!');
            setEditingEvent(null);
            setNewEvent({
                date: '',
                month: '',
                year: new Date().getFullYear().toString(),
                name: '',
                description: '',
                time: '',
                location: '',
                link: '',
                postEventLink: '',
                image: ''
            });
            fetchEvents();
        } catch (error) {
            console.error('Error updating event:', error);
            setError('Failed to update event: ' + error.message);
        }

        setLoading(false);
    };

    const handleCancel = () => {
        setEditingEvent(null);
        setNewEvent({
            date: '',
            month: '',
            year: new Date().getFullYear().toString(),
            name: '',
            description: '',
            time: '',
            location: '',
            link: '',
            postEventLink: '',
            image: ''
        });
    };

    return (
        <div className="dashboard-container">
            <div className="dashboard-header">
                <div className="header-content">
                    <div className="header-left">
                        <img src="/media/cbsai-logo.png" alt="CBS AI Logo" className="dashboard-logo" />
                        <Link to="/" className="home-link">Home</Link>
                    </div>
                    <button onClick={handleLogout} className="logout-button">Logout</button>
                </div>
            </div>

            <div className="dashboard-content">
                <div className="add-event-section">
                    <h2>{editingEvent ? 'Edit Event' : 'Add New Event'}</h2>
                    {error && <div className="error-message">{error}</div>}
                    {success && <div className="success-message">{success}</div>}
                    
                    <form onSubmit={editingEvent ? handleUpdate : handleSubmit}>
                        <div className="form-grid">
                            <div className="form-group">
                                <label htmlFor="event-date">Date (e.g., 23)</label>
                                <input
                                    id="event-date"
                                    type="text"
                                    value={newEvent.date}
                                    onChange={(e) => setNewEvent({...newEvent, date: e.target.value})}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="event-month">Month (e.g., MAR)</label>
                                <input
                                    id="event-month"
                                    type="text"
                                    value={newEvent.month}
                                    onChange={(e) => setNewEvent({...newEvent, month: e.target.value})}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="event-year">Year</label>
                                <input
                                    id="event-year"
                                    type="number"
                                    value={newEvent.year}
                                    onChange={(e) => setNewEvent({...newEvent, year: e.target.value})}
                                    min={new Date().getFullYear()}
                                    required
                                />
                            </div>
                            <div className="form-group full-width">
                                <label htmlFor="event-name">Event Name</label>
                                <input
                                    id="event-name"
                                    type="text"
                                    value={newEvent.name}
                                    onChange={(e) => setNewEvent({...newEvent, name: e.target.value})}
                                    required
                                />
                            </div>
                            <div className="form-group full-width">
                                <label htmlFor="event-description">Description</label>
                                <textarea
                                    id="event-description"
                                    value={newEvent.description}
                                    onChange={(e) => setNewEvent({...newEvent, description: e.target.value})}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="event-time">Time (e.g., 14:00 - 16:00)</label>
                                <input
                                    id="event-time"
                                    type="text"
                                    value={newEvent.time}
                                    onChange={(e) => setNewEvent({...newEvent, time: e.target.value})}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="event-location">Location</label>
                                <input
                                    id="event-location"
                                    type="text"
                                    value={newEvent.location}
                                    onChange={(e) => setNewEvent({...newEvent, location: e.target.value})}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="event-link">Link</label>
                                <input
                                    id="event-link"
                                    type="text"
                                    value={newEvent.link}
                                    onChange={(e) => setNewEvent({...newEvent, link: e.target.value})}
                                    placeholder="www.example.com"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="event-post-link">Post-Event Link (optional)</label>
                                <input
                                    id="event-post-link"
                                    type="text"
                                    value={newEvent.postEventLink}
                                    onChange={(e) => setNewEvent({...newEvent, postEventLink: e.target.value})}
                                    placeholder="www.example.com"
                                />
                            </div>
                            <div className="form-group full-width">
                                <label htmlFor="event-image">Event Image</label>
                                <div className="image-upload-container">
                                    <label className="image-upload-label" htmlFor="event-image-input">
                                        {selectedImage ? 'Change Image' : 'Upload Image'}
                                    </label>
                                    <input
                                        id="event-image-input"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        className="image-input"
                                    />
                                    {imagePreview && (
                                        <div className="image-preview">
                                            <img src={imagePreview} alt="Preview" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="button-group">
                            <button type="submit" className="submit-button" disabled={loading}>
                                {loading ? (editingEvent ? 'Updating...' : 'Adding...') : (editingEvent ? 'Update Event' : 'Add Event')}
                            </button>
                            {editingEvent && (
                                <button type="button" className="cancel-button" onClick={handleCancel}>
                                    Cancel Edit
                                </button>
                            )}
                        </div>
                    </form>
                </div>

                <div className="events-list-section">
                    <h2>Current Events</h2>
                    <div className="events-grid">
                        {events.map(event => (
                            <div key={event.id} className="event-card">
                                {event.image && (
                                    <div className="event-image">
                                        <img src={event.image} alt={event.name} />
                                    </div>
                                )}
                                <div className="event-details">
                                    <h3>{event.name}</h3>
                                    <p>{event.description}</p>
                                    <div className="event-meta">
                                        <span>{event.date} {event.month} {event.year}</span>
                                        <span>{event.time}</span>
                                    </div>
                                    {event.link && (
                                        <div className="event-link">
                                            <span className="link-label">Link:</span>
                                            <a 
                                                href={event.link} 
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                            >
                                                {event.link}
                                            </a>
                                        </div>
                                    )}
                                </div>
                                <div className="event-actions">
                                    <button 
                                        onClick={() => handleEdit(event)}
                                        className="edit-button"
                                    >
                                        Edit
                                    </button>
                                    <button 
                                        onClick={() => handleDelete(event.id)}
                                        className="delete-button"
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard; 