import React, { useState } from 'react';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import './login.css';
import { auth } from '../firebase';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [resetEmailSent, setResetEmailSent] = useState(false);
    const [resetError, setResetError] = useState('');
    const navigate = useNavigate();

    const isValidDomain = (email) => {
        return email.endsWith('@cbsai.dk') || email.endsWith('@parlabranding.dk');
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            // Check if email is allowed
            if (!email.endsWith('@parlabranding.dk') && email !== 'ai@cbsstudents.dk') {
                setLoading(false);
                setError('Unauthorized email');
                return;
            }

            await signInWithEmailAndPassword(auth, email, password);
            navigate('/admin/dashboard');
        } catch (error) {
            console.error('Login error:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        if (!email) {
            setError('Please enter your email address');
            return;
        }

        if (!isValidDomain(email)) {
            setError('Please use an email from @cbsai.dk domain');
            return;
        }

        try {
            await sendPasswordResetEmail(auth, email);
            setResetEmailSent(true);
            setError('');
            setResetError('');
        } catch (error) {
            console.error('Password reset error:', error);
            setResetError('Failed to send reset email. Please try again.');
        }
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <div className="login-header">
                    <img src="/media/cbsai-logo.png" alt="CBS AI Logo" className="login-logo" />
                    <h2>Admin Login</h2>
                    <p className="login-subtitle">
                        You need to have an authorized email to login
                    </p>
                </div>

                {error && <div className="error-message">{error}</div>}
                {resetError && <div className="error-message">{resetError}</div>}
                {resetEmailSent && (
                    <div className="success-message">
                        Password reset email sent! Please check your inbox.
                    </div>
                )}

                <form onSubmit={handleLogin} className="login-form">
                    <div className="form-group">
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            required
                        />
                    </div>
                    <div className="button-group">
                        <button 
                            type="submit" 
                            className="login-button" 
                            disabled={loading}
                        >
                            {loading ? 'Logging in...' : 'Login'}
                        </button>
                        <button 
                            type="button"
                            onClick={handleForgotPassword}
                            className="forgot-password-button"
                        >
                            Forgot Password?
                        </button>
                    </div>
                </form>
                
                <Link to="/" className="go-back-button">
                    ← Back to Home
                </Link>
            </div>
        </div>
    );
};

export default Login;
