import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy, where } from 'firebase/firestore';
import { db } from '../firebase';
import './styles/event.css';

const Event = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        console.log('Fetching events...');
        const eventsRef = collection(db, 'events');
        
        // Simple query to get all events
        const q = query(
          eventsRef,
          orderBy('year', 'asc')
        );

        const querySnapshot = await getDocs(q);
        console.log('Found documents:', querySnapshot.size);

        // Process and sort all events
        let allEvents = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // Sort events by date
        allEvents.sort((a, b) => {
          // Compare years
          const yearDiff = parseInt(a.year) - parseInt(b.year);
          if (yearDiff !== 0) return yearDiff;

          // Compare months
          const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
          const monthA = months.indexOf(a.month.toUpperCase());
          const monthB = months.indexOf(b.month.toUpperCase());
          if (monthA !== monthB) return monthA - monthB;

          // Compare days
          return parseInt(a.date) - parseInt(b.date);
        });

        // Take only the first 3 events
        const upcomingEvents = allEvents.slice(0, 3);
        console.log('Upcoming events:', upcomingEvents);
        
        setEvents(upcomingEvents);
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  // Add this function at the top of your component to check if event is past
  const isEventPast = (event) => {
    const eventDate = new Date(
      parseInt(event.year),
      ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'].indexOf(event.month),
      parseInt(event.date)
    );
    return eventDate < new Date();
  };

  if (loading) {
    return (
      <section id="events" className="event-section">
        <div className="event-container">
          <div className="event-content">
            <h2 className="event-title">
              <span className="event-number">004.</span>
              Loading Events...
            </h2>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section id="events" className="event-section">
      <div className="event-container">
        <div className="event-content">
          <h2 className="event-title">
            <span className="event-number">004.</span>
            Upcoming Events
          </h2>
          
          <div className="event-grid">
            {events.map(event => (
              <div key={event.id} className="event-card">
                {event.image && (
                  <div className="event-image">
                    <img src={event.image} alt={event.name} />
                  </div>
                )}
                <div className="event-date">
                  <span className="date-number">{event.date}</span>
                  <span className="date-month">{event.month}</span>
                  <span className="date-year">{event.year}</span>
                </div>
                <div className="event-details">
                  <h3 className="event-name">{event.name}</h3>
                  <p className="event-description">{event.description}</p>
                  <div className="event-meta">
                    <span className="event-time">{event.time}</span>
                    {((!isEventPast(event) && event.link) || (isEventPast(event) && event.postEventLink)) && (
                      <a 
                        href={isEventPast(event) ? event.postEventLink : event.link} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="event-link-button"
                      >
                        {isEventPast(event) ? 'See more' : 'Sign up'}
                        {isEventPast(event) ? (
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z" clipRule="evenodd" />
                          </svg>
                        ) : (
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                          </svg>
                        )}
                      </a>
                    )}
                    <span className="event-location">{event.location}</span>
                  </div>
                </div>
              </div>
            ))}
            {events.length === 0 && (
              <div className="no-events">
                <p>No upcoming events at the moment.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Event;
