import React from 'react';
import './styles/about.css';

const About = () => {
    const teamMembers = [
        {
            name: "Nicolai Malik Jeppesen",
            role: "Head of Events, President. Studying HA Almen",
            image: "/media/Nicolai.jpg",
            linkedin: "https://www.linkedin.com/in/nicolai-malik-jeppesen-59457a227/"
        },
        {
            name: "Boxuan Yang",
            role: "Head of Funding, Board Member. Studying IB",
            image: "/media/Boxuan.jpeg",
            linkedin: "https://www.linkedin.com/in/boxuan-yang/"
        },
        {
            name: "Alexander Brun",
            role: "Head of Volunteers, Board Member. Studying HA Almen",
            image: "/media/Alexander.jpg",
            linkedin: "https://www.linkedin.com/in/alexander-brun-26288116b/"
        },
        {
            name: "Oskar Bürhmann",
            role: "Head of Partnerships & Relations, Borad Member. Studying HA Almen",
            image: "/media/Oskar.jpg",
            linkedin: "https://www.linkedin.com/in/oskar-forum-b%C3%BChrmann-1a349b219/"
        },
        {
            name: "Sebastian Sandorff Jacobsen",
            role: "Head of Finance, Cashier. Studying HA Almen",
            image: "/media/Sebastian.jpg",
            linkedin: "https://www.linkedin.com/"
        },
        {
            name: "Simon Fisker",
            role: "Head of Marketing, Vice President. Studying HA (it.)",
            image: "/media/Simon.jpeg",
            linkedin: "https://www.linkedin.com/"
        }
    ];

    return (
        <section id="team" className="about-section">
            <div className="about-container">
                <div className="about-content">
                    <h2 className="about-title">
                        <span className="about-number">003.</span>
                        Meet Our Team
                    </h2>
                    
                    <div className="team-grid">
                        {teamMembers.map((member, index) => (
                            <div className="team-card" key={index}>
                                <div className="member-image-container">
                                    <img 
                                        src={member.image} 
                                        alt={member.name} 
                                        className="member-image"
                                    />
                                </div>
                                <div className="member-info">
                                    <div className="name-with-social">
                                        <h3 className="member-name">{member.name}</h3>
                                        {member.linkedin !== "https://www.linkedin.com/" ? (
                                            <a 
                                                href={member.linkedin} 
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                                className="name-linkedin-link"
                                            >
                                                <svg viewBox="0 0 24 24" fill="currentColor">
                                                    <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
                                                </svg>
                                            </a>
                                        ) : (
                                            <span className="name-linkedin-link disabled">
                                                <svg viewBox="0 0 24 24" fill="currentColor">
                                                    <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
                                                </svg>
                                            </span>
                                        )}
                                    </div>
                                    <p className="member-role">{member.role}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
