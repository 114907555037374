import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, query, limit, orderBy, collection } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAofoqcUIKTr49JHVjMsEZ2mRIUo4fWZas",
    authDomain: "parla-cbsai.firebaseapp.com",
    projectId: "parla-cbsai",
    storageBucket: "parla-cbsai.appspot.com",
    messagingSenderId: "908905152033",
    appId: "1:908905152033:web:e43a6effc9bc3258eead60"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export default app;