import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './login.css';
import { auth } from '../firebase';

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            // Check if email is allowed
            if (!email.endsWith('@parlabranding.dk') && email !== 'ai@cbsstudents.dk') {
                setLoading(false);
                setError('Unauthorized email');
                return;
            }

            await createUserWithEmailAndPassword(auth, email, password);
            navigate('/admin/dashboard');
        } catch (error) {
            console.error('Registration error:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <div className="login-header">
                    <img src="/media/cbsai-logo.png" alt="CBS AI Logo" className="login-logo" />
                    <h2>Register Admin Account</h2>
                    <p className="login-subtitle">
                        Only @parlabranding.dk emails and ai@cbsstudents.dk are allowed
                    </p>
                </div>

                {error && <div className="error-message">{error}</div>}

                <form onSubmit={handleRegister} className="login-form">
                    <div className="form-group">
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            required
                        />
                    </div>
                    <button 
                        type="submit" 
                        className="login-button" 
                        disabled={loading}
                    >
                        {loading ? 'Registering...' : 'Register'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Register; 