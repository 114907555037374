import React from 'react';
import './styles/footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    const handleNavClick = (e, targetId) => {
        e.preventDefault();
        const duration = window.innerWidth <= 768 ? 2500 : 1500;

        // Get start position
        const startPosition = window.pageYOffset;
        // For home, target position is 0 (top of page), otherwise get element position
        const targetPosition = targetId === 'home' ? 0 : document.getElementById(targetId)?.getBoundingClientRect().top + window.pageYOffset;

        if (targetPosition !== undefined) {
            const startTime = performance.now();

            function animation(currentTime) {
                const timeElapsed = currentTime - startTime;
                const progress = Math.min(timeElapsed / duration, 1);
                
                const easeInOutCubic = progress => {
                    return progress < 0.5
                        ? 4 * progress * progress * progress
                        : 1 - Math.pow(-2 * progress + 2, 3) / 2;
                };

                const run = easeInOutCubic(progress);
                window.scrollTo(0, startPosition + (targetPosition - startPosition) * run);

                if (progress < 1) {
                    requestAnimationFrame(animation);
                }
            }

            requestAnimationFrame(animation);
        }
    };

    return (
        <footer className="footer-section">
            <div className="footer-container">
                <div className="footer-grid">
                    <div className="footer-brand">
                        <div className="footer-logo-section">
                            <img 
                                src="/media/cbsai-logo.png" 
                                alt="CBS AI Logo" 
                                className="footer-logo" 
                            />
                        </div>
                        <p className="footer-description">
                            Pioneering the future of business intelligence through 
                            artificial intelligence innovation and research.
                        </p>
                        <div className="footer-social">
                            <a 
                                href="https://www.instagram.com/cbs_ai/" 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="social-link"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
                                </svg>
                            </a>
                            <a 
                                href="https://www.linkedin.com/company/100301567/" 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="social-link"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
                                </svg>
                            </a>
                            <a 
                                href="https://open.spotify.com/show/1Jkc1Niu1tknJqLrKjWVIp?si=4c9617193d3f49ab" 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="social-link"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.66 0 12 0zm5.521 17.34c-.24.359-.66.48-1.021.24-2.82-1.74-6.36-2.101-10.561-1.141-.418.122-.779-.179-.899-.539-.12-.421.18-.78.54-.9 4.56-1.021 8.52-.6 11.64 1.32.42.18.479.659.301 1.02zm1.44-3.3c-.301.42-.841.6-1.262.3-3.239-1.98-8.159-2.58-11.939-1.38-.479.12-1.02-.12-1.14-.6-.12-.48.12-1.021.6-1.141C9.6 9.9 15 10.561 18.72 12.84c.361.181.54.78.241 1.2zm.12-3.36C15.24 8.4 8.82 8.16 5.16 9.301c-.6.179-1.2-.181-1.38-.721-.18-.601.18-1.2.72-1.381 4.26-1.26 11.28-1.02 15.721 1.621.539.3.719 1.02.419 1.56-.299.421-1.02.599-1.559.3z"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                    
                    <div className="footer-group">
                        <h3>Quick Links</h3>
                        <ul>
                            <li><a href="#" onClick={(e) => handleNavClick(e, 'home')}>Home</a></li>
                            <li><a href="#mission" onClick={(e) => handleNavClick(e, 'mission')}>Mission</a></li>
                            <li><a href="#team" onClick={(e) => handleNavClick(e, 'team')}>About Us</a></li>
                            <li><a href="#events" onClick={(e) => handleNavClick(e, 'events')}>Events</a></li>
                            <li><a href="#contact" onClick={(e) => handleNavClick(e, 'contact')}>Contact</a></li>
                            <li><a href="#newsletter" onClick={(e) => handleNavClick(e, 'newsletter')}>Newsletter</a></li>
                        </ul>
                    </div>
                    
                    <div className="footer-image">
                        <img 
                            src="/media/cbs-solbjergplads.jpg" 
                            alt="CBS Solbjerg Plads" 
                            className="footer-decoration" 
                        />
                        <p className="footer-address">
                            Solbjerg Plads 3, 2000 Frederiksberg
                        </p>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="footer-line"></div>
                    <div className="footer-info">
                        <div className="copyright">
                            © 2024 Skabt af <a 
                                href="https://www.parlabranding.dk" 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                Parla Branding
                            </a>. Alle rettigheder forbeholdes.
                        </div>
                        <div className="footer-meta">
                            <a href="/privacy">Privacy Policy</a>
                            <span className="divider">•</span>
                            <a href="/terms">Terms of Service</a>
                            <span className="divider">•</span>
                            <Link to="/admin" className="admin-link">Admin</Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
