import React, { useState } from 'react';
import './styles/newsletter.css';

const Newsletter = () => {
    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Subscribed with email:', email);
        setEmail('');
    };

    return (
        <section id="newsletter" className="newsletter-section">
            <div className="newsletter-container">
                <div className="newsletter-content">
                    <h2 className="newsletter-title">
                        <span className="newsletter-number">006.</span>
                        Stay Connected
                    </h2>
                    
                    <div className="newsletter-grid">
                        <div className="newsletter-text">
                            <p className="newsletter-description">
                                Join our community to receive updates about AI innovations, 
                                upcoming events, and exclusive insights from industry experts.
                            </p>
                        </div>

                        <div className="newsletter-form-container">
                            <div className="form-header">
                                <div className="form-badge">
                                    <span className="badge-pulse"></span>
                                    <span className="badge-text">Newsletter</span>
                                </div>
                            </div>
                            <form onSubmit={handleSubmit} className="newsletter-form">
                                <div className="form-group">
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Enter your email address"
                                        required
                                        className="input-field"
                                    />
                                </div>
                                <button type="submit" className="cta-button primary">
                                    <span className="button-content">
                                        Subscribe Now
                                        <span className="button-arrow">→</span>
                                    </span>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Newsletter;
