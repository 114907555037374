import React, { useEffect } from 'react';
import Header from './components/header';
import Hero from './components/hero';
import Mission from './components/mission';
import Event from './components/event';
import Contact from './components/contact';
import Newsletter from './components/newsletter';
import Footer from './components/footer';
import About from './components/about';
import './App.css';
import './components/styles/shared.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './firebase-admin/login';
import Dashboard from './firebase-admin/dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import Register from './firebase-admin/register';

function App() {
  const handleNavClick = (e, targetId) => {
    e.preventDefault();
    // Longer duration for mobile
    const duration = window.innerWidth <= 768 ? 2500 : 1500;  // 2.5s for mobile, 1.5s for desktop

    if (targetId === 'home') {
      const startPosition = window.pageYOffset;
      const startTime = performance.now();

      function animation(currentTime) {
        const timeElapsed = currentTime - startTime;
        const progress = Math.min(timeElapsed / duration, 1);
        
        const easeInOutQuart = progress => {
          return progress < 0.5
            ? 8 * progress * progress * progress * progress
            : 1 - Math.pow(-2 * progress + 2, 4) / 2;
        };

        const run = easeInOutQuart(progress);
        window.scrollTo(0, startPosition * (1 - run));

        if (progress < 1) {
          requestAnimationFrame(animation);
        }
      }

      requestAnimationFrame(animation);
    } else {
      const element = document.getElementById(targetId);
      if (element) {
        const offset = 80;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - offset;
        const startPosition = window.pageYOffset;
        const distance = offsetPosition - startPosition;
        const startTime = performance.now();

        function animation(currentTime) {
          const timeElapsed = currentTime - startTime;
          const progress = Math.min(timeElapsed / duration, 1);
          
          const easeInOutQuart = progress => {
            return progress < 0.5
              ? 8 * progress * progress * progress * progress
              : 1 - Math.pow(-2 * progress + 2, 4) / 2;
          };

          const run = easeInOutQuart(progress);
          window.scrollTo(0, startPosition + distance * run);

          if (progress < 1) {
            requestAnimationFrame(animation);
          }
        }

        requestAnimationFrame(animation);
      }
    }
  };

  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = "https://web.cmp.usercentrics.eu/modules/autoblocker.js";
    document.body.appendChild(script1);

    const script2 = document.createElement('script');
    script2.id = "usercentrics-cmp";
    script2.src = "https://web.cmp.usercentrics.eu/ui/loader.js";
    script2.setAttribute('data-settings-id', 'dThbKOcaJgoHc2');
    script2.async = true;
    document.body.appendChild(script2);
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/admin" element={<Login />} />
        <Route path="/admin/dashboard" element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        } />
        <Route path="/admin/register" element={<Register />} />
        <Route path="/" element={
          <div className="App">
            <Header handleNavClick={handleNavClick} />
            <Hero handleNavClick={handleNavClick} />
            <Mission />
            <About />
            <Event />
            <Contact />
            <Newsletter />
            <Footer />
          </div>
        } />
      </Routes>
    </Router>
  );
}

export default App;
