import React, { useState, useEffect } from 'react';
import './styles/header.css';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleNavClick = (e, targetId) => {
    e.preventDefault();
    // Longer duration for mobile
    const duration = window.innerWidth <= 768 ? 2500 : 1500;  // 2.5s for mobile, 1.5s for desktop

    if (targetId === 'home') {
        const startPosition = window.pageYOffset;
        const startTime = performance.now();

        function animation(currentTime) {
            const timeElapsed = currentTime - startTime;
            const progress = Math.min(timeElapsed / duration, 1);
            
            // Gentler easing function
            const easeInOutQuart = progress => {
                return progress < 0.5
                    ? 8 * progress * progress * progress * progress
                    : 1 - Math.pow(-2 * progress + 2, 4) / 2;
            };

            const run = easeInOutQuart(progress);
            window.scrollTo(0, startPosition * (1 - run));

            if (progress < 1) {
                requestAnimationFrame(animation);
            }
        }

        requestAnimationFrame(animation);
    } else {
        const element = document.getElementById(targetId);
        if (element) {
            const offset = 80;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - offset;
            const startPosition = window.pageYOffset;
            const distance = offsetPosition - startPosition;
            const startTime = performance.now();

            function animation(currentTime) {
                const timeElapsed = currentTime - startTime;
                const progress = Math.min(timeElapsed / duration, 1);
                
                const easeInOutQuart = progress => {
                    return progress < 0.5
                        ? 8 * progress * progress * progress * progress
                        : 1 - Math.pow(-2 * progress + 2, 4) / 2;
                };

                const run = easeInOutQuart(progress);
                window.scrollTo(0, startPosition + distance * run);

                if (progress < 1) {
                    requestAnimationFrame(animation);
                }
            }

            requestAnimationFrame(animation);
        }
    }
    setIsMenuOpen(false);
  };

  return (
    <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
      <div className="header-container">
        <div className="logo">
          <img 
              src="/media/cbsai-logo.png" 
              alt="CBS AI Logo" 
              className="logo-image" 
          />
        </div>

        <nav className={`nav-menu ${isMenuOpen ? 'active' : ''}`}>
          <ul className="nav-links">
            <li>
              <a href="#" onClick={(e) => handleNavClick(e, 'home')}>
                <span className="nav-number">001.</span>Home
              </a>
            </li>
            <li>
              <a href="#mission" onClick={(e) => handleNavClick(e, 'mission')}>
                <span className="nav-number">002.</span>Mission
              </a>
            </li>
            <li>
              <a href="#team" onClick={(e) => handleNavClick(e, 'team')}>
                <span className="nav-number">003.</span>About Us
              </a>
            </li>
            <li>
              <a href="#events" onClick={(e) => handleNavClick(e, 'events')}>
                <span className="nav-number">004.</span>Events
              </a>
            </li>
            <li>
              <a href="#contact" onClick={(e) => handleNavClick(e, 'contact')}>
                <span className="nav-number">005.</span>Contact
              </a>
            </li>
          </ul>
          <button className="cta-button primary" onClick={(e) => handleNavClick(e, 'events')}>
            <span className="button-content">
              <svg className="button-icon" viewBox="0 0 24 24">
                <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"/>
              </svg>
              Explore Events
              <span className="button-arrow">→</span>
            </span>
          </button>
        </nav>

        <button 
          className={`menu-toggle ${isMenuOpen ? 'active' : ''}`}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-label="Toggle menu"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </header>
  );
};

export default Header;
