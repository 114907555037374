import React from 'react';
import './styles/mission.css';

const Mission = () => {
  return (
    <section id="mission" className="mission-section">
      <div className="mission-container">
        <div className="mission-content">
          <h2 className="mission-title">
            <span className="mission-number">002.</span>
            Our Mission
          </h2>
          
          <div className="mission-grid">
            <div className="mission-text">
              <p className="mission-description">
                CBS AI, a hub created by AI enthusiasts and for AI enthusiasts. Our team, passionate about artificial intelligence, aims to foster innovation and share knowledge within our community. CBS AI stands out by actively engaging in the AI revolution, offering insights, resources, and collaboration for both novices and experts.
              </p>
              <div className="mission-stats">
                <div className="stat">
                  <span className="stat-highlight">15+</span>
                  <span className="stat-label">Volunteers</span>
                </div>
                <div className="stat">
                  <span className="stat-highlight">500+</span>
                  <span className="stat-label">Engaged Students</span>
                </div>
              </div>
            </div>
            
            <div className="mission-visual">
              <div className="visual-grid">
                <div className="grid-item">Research Excellence</div>
                <div className="grid-item">Innovation Hub</div>
                <div className="grid-item">Industry Connection</div>
                <div className="grid-item">Student Success</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mission;
